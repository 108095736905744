import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout/Layout";
import textData from "../data/testTexts";
import SolutionStep2 from "../components/SolutionStepLong";
import { SEO } from "../components/Seo";
import { OUR_SOLUTIONS } from "../constants/solutions";
import Accordion from "../components/Accordion";
import seoData from "../data/seoData.json";

const Solutions = () => (
  <Layout>
    <div className="absolute z-0 w-[100vw] max-w-[100%]">
      <div className="bgfilter" />
      <StaticImage
        src="../images/whypeople_imagen5.jpg"
        objectFit="cover"
        alt="whypeople"
        style={{
          width: "100vw",
          maxWidth: "100%",
          position: "fixed",
          zIndex: "-5",
        }}
      />
    </div>
    <div className="py-20 lg:pb-10 max-w-md md:max-w-3xl lg:max-w-4xl xl:max-w-6xl 3xl:max-w-7xl 4xl:max-w-[1900px]  mx-auto text-primary-darkest relative z-5">
      <div className="flex flex-row relative z-5">
        <div className="border-b-2 border-b-solid border-b-primary-default w-[10%]" />

        <h1 className="text-4xl xl:text-5xl 2xl:text-6xl 4xl:text-7xl 7xl:text-8xl font-bold leading-none text-center text-primary-darkest w-[80%]">
          Nuestras Soluciones
        </h1>
        <div className="border-b-2 border-b-solid border-b-primary-default w-[10%]" />
      </div>
      <div className="hidden lg:block">
        <div className="flex items-center justify-center py-7">
          <div
            className="flex shadow-md hover:shadow-lg focus:shadow-lg grid-navbar-solutions "
            role="group"
          >
            {OUR_SOLUTIONS.map((ele, index) => (
              <AnchorLink key={ele} href={`#solutions${index + 1}`}>
                <button
                  type="button"
                  className="rounded-none border-1 border-primary-lighter inline-block px-4 py-1.5 bg-primary-default text-white font-medium text-xl leading-tight normal-case hover:bg-primary-lighter focus:bg-primary-darker focus:outline-none focus:ring-0 active:bg-primary-darker transition duration-150 ease-in-out w-full min-h-[70px]"
                >
                  <span className="text-2xl">{index + 1} </span>
                  {ele}
                </button>
              </AnchorLink>
            ))}
          </div>
        </div>
      </div>
    </div>

    <div className="lg:pb-10 hidden lg:block">
      {textData.solutions.map((sol, index) => (
        <SolutionStep2
          initVerticalLine={index !== 0}
          endLine={index !== textData.solutions.length - 1}
          key={sol.id}
          id={sol.id}
          content={sol.content}
          title={sol.title}
          direction={index}
        />
      ))}
    </div>

    <Accordion
      data={textData.solutions}
      className="lg:hidden [&_li]:list-roman relative z-5"
    />
  </Layout>
);

export default Solutions;

export const Head = () => (
  <SEO
    title="Conoce todas nuestros servicios | WhyPeople"
    description="Diagnóstico, gestión de la organización y las personas, retribución, transformación cultural, planes de igualdad y soluciones estratégicas"
    keywords={["Soluciones de WhyPeoople"]}
  >
    <script type="application/ld+json">{JSON.stringify(seoData)}</script>
  </SEO>
);
