import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

const SolutionStep = ({
  id,
  direction,
  title,
  content,
  initVerticalLine,
  endLine,
}) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const icons = [
    "01Diagnostico.png",
    "02GestionOrganizacion.png",
    "03Diseno-Retribucion.png",
    "04GestioPersonas.png",
    "05TransformacionCultural.png",
    "06PlanesIgualdad.png",
    "07Soluciones-Estrategicas.png",
  ];
  const imgData = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            gatsbyImageData(placeholder: TRACED_SVG)
            id
          }
        }
      }
    }
  `);

  const imgToShow = imgData.allImageSharp.edges.filter((i) => {
    const temp = i.node.gatsbyImageData.images.fallback.src.split("/");
    const iName = temp[temp.length - 1];
    return iName === icons[direction];
  });

  const image = getImage(imgToShow[0].node);

  return (
    <div id={id} className="relative z-5">
      {/** Top Vertical Lines */}

      <div
        className={`w-4/5 xl:w-[1250px] 2xl:w-[1450px]  3xl:w-[1650px] 4xl:w-[1900px] 5xl:w-[2200px] ${
          initVerticalLine ? "border-l-2" : "border-l-0"
        } border-l-solid border-l-primary-default h-12 sm:h-24 mx-auto`}
      />

      {/* title */}
      <div
        className={`flex mx-auto w-4/5 xl:w-[1250px] 2xl:w-[1450px]  3xl:w-[1650px] 4xl:w-[1900px] 5xl:w-[2200px] text-center border-y-2 border-y-solid border-y-primary-default sm:border-none ${
          direction === 0 && "border-t-0"
        }`}
      >
        <div
          className={` ${
            direction === 0
              ? ""
              : "sm:border-t-2 sm:border-t-solid sm:border-t-primary-default"
          }  h-12 w-0  sm:w-1/4  mx-auto self-start`}
        />

        <h2
          className={`py-4 sm:p-0 sm:w-2/4 font-extrabold uppercase text-primary-darkest  ${
            title.length > 50 ? "text-2xl sm:text-2xl" : "text-3xl sm:text-3xl"
          }`}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <button
          type="button"
          onClick={scrollToTop}
          id="topButton"
          className="p-2 text-primary-darker"
        >
          <p>
            <svg
              className="w-8 h-8"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2.5"
                d="M5 10l7-7m0 0l7 7m-7-7v18"
              />
            </svg>
          </p>
        </button>
        <div className="sm:border-t-2 sm:border-t-solid sm:border-t-primary-default w-0 sm:w-1/4 h-0 mx-auto self-end" />
      </div>

      {/** Top Vertical Lines */}
      <div className=" border-r-2 border-r-solid border-r-primary-default w-4/5 xl:w-[1250px] 2xl:w-[1450px]  3xl:w-[1650px] 4xl:w-[1900px] 5xl:w-[2200px] h-12 sm:h-24 mx-auto" />
      <div className=" border-r-2 border-r-solid border-r-primary-default w-4/5 xl:w-[1250px] 2xl:w-[1450px]  3xl:w-[1650px] 4xl:w-[1900px] 5xl:w-[2200px] h-12 sm:h-24 mx-auto" />
      {/** End Top Vertical Lines */}

      {/* step */}
      <div className="mx-auto lg:w-4/5 xl:w-[1250px] 2xl:w-[1450px]  3xl:w-[1650px] 4xl:w-[1900px] 5xl:w-[2200px] flex">
        {endLine && direction % 2 === 0 && (
          <div className="border-b-2 border-b-solid border-b-primary-default w-0 lg:w-[10%] h-54" />
        )}
        {/* card */}
        <div
          className={`w-4/5 lg:w-full xl:w-[1250px] 2xl:w-[1450px] 3xl:w-[1650px] 4xl:w-[1900px] 5xl:w-[2200px] min-h-[230px] rounded ${
            direction % 2 !== 0 && "flex-row-reverse"
          } flex mx-auto drop-shadow-2xl bg-white ${
            direction % 2 !== 0 ? "pl-6" : "pr-6"
          }`}
        >
          {content.map((cont) => {
            if (cont.text.includes("<ul>")) {
              return (
                <div
                  key={`cont-${cont.id}`}
                  className="p-12 [&_li]:mb-4 [&_li:last-of-type]:mb-0 [&_li]:list-roman [&_strong]:text-primary-default [&_strong]:font-semibold  text-lg 2xl:text-xl 3xl:text-2xl"
                  dangerouslySetInnerHTML={{ __html: cont.text }}
                />
              );
            }
            return (
              <p
                key={`cont-${cont.id}`}
                className="p-12 [&_li]:mb-4 [&_li:last-of-type]:mb-0 [&_li]:list-roman [&_strong]:text-primary-default [&_strong]:font-semibold  text-lg 2xl:text-xl 3xl:text-2xl"
                dangerouslySetInnerHTML={{ __html: cont.text }}
              />
            );
          })}
          <div
            className={`w-48  ${
              direction % 2 !== 0 ? "rounded-l-full" : "rounded-r-full"
            } rounded rounded-l rounded-r relative mx-auto hidden md:block my-auto`}
          >
            <GatsbyImage image={image} alt={`whypeople - ${title}`} />
          </div>
        </div>
        {direction % 2 !== 0 && (
          <div className="border-t-2 border-t-solid border-t-primary-default w-0 lg:w-[10%] h-54 " />
        )}
      </div>
      {endLine ? (
        <div className="w-4/5 xl:w-[1250px] 2xl:w-[1450px]  3xl:w-[1650px] 4xl:w-[1900px] 5xl:w-[2200px] border-l-2 border-l-solid border-l-primary-default h-12 sm:h-24 mx-auto" />
      ) : null}
    </div>
  );
};

export default SolutionStep;
